.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
}

.modal-container {
  position: absolute;
  background-color: #3e5156;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

.close-icon {
  float: right;
  cursor: pointer;
  padding-bottom: 20px;
}

.loading {
  margin: 0 auto;
  color: white;
}
