@import '../../../../styles/colors.scss';

// Background variables

.userDropdown {
  display: flex;
  margin: 0px 24px 0px 24px;
  //position: relative;

  &-options-container {
    padding: 24px;
    background-color: #ffffff;
    width: 288px;
    top: 90px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    box-shadow: 0px 1px 10px rgba(174, 186, 197, 0.45);
    border-radius: 10px;
    z-index: 100;

    &-iconed-options {
      display: flex;

      &-text-iconed {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #3e5156;
      }
    }

    &-options {
      display: flex;
      width: 100%;
      justify-content: center;

      &-text {
        justify-content: center;
        width: 100%;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        padding: 16px 0px 16px 0px;
        margin: 0px;
        letter-spacing: 0.02em;
        color: #3e5156;
        border-top: 1px solid #ccd0d6;
        border-bottom: 1px solid #ccd0d6;
      }

      &-policy {
        justify-content: center;
        width: 100%;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        display: flex;
        letter-spacing: 0.05em;
        color: #94a1a6;
        padding: 16px 0px 0px 0px;
        margin: 0px;
      }
    }

    img {
      margin-right: 5px;
    }
  }

  &-options-container-none {
    display: none;
  }

  &-user-information {
    display: flex;
    flex-direction: column;
    margin: 0px 5px 0px 0px;

    &-name {
      font-family: Blacker Pro Display;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 35px;
      display: flex;
      align-items: flex-end;
      color: #25303b;
      margin: 0px;
      margin: 0px 0px 5px 0px;
    }

    &-mail {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: #3e5156;
      margin: 0px;
    }
  }

  img {
    width: 24px;
    height: 24px;
    align-self: center;
  }
}
