@import '../../../../styles/colors.scss';

// COLORS
$text-color: $slate;
$border-color: $gray3;

/////////////////////
// ELEMENT STYLING //
/////////////////////

.bannerSelector {
  border: 1px solid $border-color;
  border-radius: 10px;
  width: 196px;
  height: 260px;
  margin-top: 20px;
  padding-top: 16px;
  cursor: pointer;

  #blackIcon {
    display: block;
    margin: auto;
  }

  #whiteIcon {
    display: none;
    margin: auto;
  }

  &:hover {
    background-color: #71777d;

    p {
      color: white;
    }

    #blackIcon {
      display: none;
    }

    #whiteIcon {
      display: block;
    }
  }

  &-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: $text-color;
    margin: 10px 0px 0px 0px;
  }

  &-placeholder {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    color: $text-color;
    margin: 8px 0px 0px 0px;
  }
}
