@import '../../../../styles/colors.scss';

.addTypes {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  height: 792px;

  &-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #25303b;
    margin: 0px 0px 32px 0px;
  }

  img {
    cursor: pointer;
  }

  &-imgDisplayed {
    display: inline-block;
  }

  &-imgNoneDisplayed {
    display: none;
  }

  &-videDisplayed {
    display: inline-block;
  }

  &-videoNoneDisplayed {
    display: none;
  }

  #imgBanner {
    margin-right: 12px;
  }

  #videoBanner {
    margin-left: 12px;
  }

  &-nextButton {
    width: 85px;
    height: 47px;
    left: 0px;
    top: 0px;
    background: #d6ef59;
    border-radius: 8px;
    border: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #25303b;
    align-self: center;
    margin-top: 32px;
    cursor: pointer;

    &:hover {
      background-color: #ecf3b6;
      color: #71777d;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    align-self: center;

    &-column:first-of-type {
      margin-right: 50px;
    }

    &-column {
      h3 {
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #25303b;
        display: table;
        white-space: nowrap;

        &:before,
        &:after {
          border-top: 2px solid #d6ef59;
          content: '';
          display: table-cell;
          position: relative;
          top: 0.5em;
          width: 45%;
        }
        &:before {
          right: 1.5%;
        }
        &:after {
          left: 1.5%;
        }
      }
    }
  }
}
