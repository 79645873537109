@import '../../../../../../../../styles/colors.scss';

// COLOR VARIABLES //

$search-border-color: $gray4;
$search-placeholder-color: $gray3;

/////////////////////
// ELEMENT STYLING //
/////////////////////

.search-container {
  display: flex;
  border-radius: 4px;
  // width: 100%;
  height: 32px;
  border: 1px solid $search-border-color;
  background-color: white;
  padding: 5px 4px 7px 11px;
  position: relative;
  margin-bottom: 14px;

  input {
    border: none;
    display: block;
    padding-right: 30px;
    padding-top: 5px;
    width: 100%;

    &::placeholder {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: $search-placeholder-color;
    }

    &:focus {
      border: none;
      outline: none !important;
    }
  }

  img {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
