.recoverPassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  height: 100%;

  &-form {
    width: 600px;
    height: 750px;
    padding: 71px 76px 71px 76px;

    &-input-container {
      margin: 54px 0px 32px 0px;
    }

    &-access {
      padding: 0px;
      width: fit-content;
      height: 48px;
      margin: auto;
      margin-bottom: 52px;
    }

    &-bottom {
      display: flex;
      justify-content: center;
    }
  }

  #recoveryText {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #25303b;
    margin: 0px 0px 10px 0px;
    justify-content: center;
  }

  #forgotPasswordText {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #3e5156;
    justify-content: flex-end;
    margin: 0px 0px 32px 0px;
    cursor: pointer;
  }

  #bottomText {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #3e5156;
    margin: 0px 10px 0px 0px;
    cursor: pointer;
  }

  #bottomTextHere {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #3e5156;
    margin: 0px;
  }
}
