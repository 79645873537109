@import '../../styles/colors.scss';

.main-container {
  display: flex;
  flex-wrap: wrap;
}

.faqContainers {
  flex: 0 0 100%;
  margin-right: 30px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 1px 1px rgba(173, 186, 197, 0.45);
}

.support {
  min-height: 274px;
  padding-bottom: 37px;

  &-header {
    font-family: Raleway;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $slate;
    margin-left: 30px;
    padding-top: 27px;
  }

  .header-separator {
    margin-top: 21px;
    border: 1px solid $gray3;
  }

  &-description {
    margin: 20px 0px 0px 30px;
    font-family: Raleway;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2%;
    text-align: left;

    &-bottom {
      margin-top: 10px;
    }
  }

  .buttons-container {
    height: 48px;
    margin: 25px 0 0 30px;
  }

  .button {
    border: 1px solid $marine;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;

    a {
      color: inherit;
      text-decoration: none;
    }

    &-send-note {
      width: 165px;
    }

    &-book-call {
      margin-left: 16px;
      width: 135px;
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 2%;
      text-align: center;
      padding: 12px 24px 12px 24px;
    }
  }
}

.faq {
  margin-top: 16px;

  &-header {
    font-family: Raleway;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $slate;
    margin-left: 30px;
    padding-top: 27px;
  }

  .header-separator {
    margin-top: 21px;
    border: 1px solid $gray3;
  }

  &-quick-links {
    margin: 18px 0px 25px 30px;
    font-family: Raleway;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 4%;
    text-transform: uppercase;
    color: $slate;
    margin-right: 5px;
  }

  &-sub-header {
    font-style: bold;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2%;
    margin: 25px 0 13px 30px;
    color: $slate;
    height: 24px;

    &-icon {
      height: 16px;
      width: 16px;
      margin: 5.37px 0 5.37px 8px;
    }

    &-separator {
      margin: 13px 0 0px 0;
      border: 1px solid $gray3;
    }
  }

  &-dropdown-container {
    margin: 23px 0 0 54px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2%;
    color: $slate;
    height: 24px;
  }

  &-back-to-top-container {
    margin: 46px 0 0 54px;
    padding-bottom: 54px;

    &-anchor {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }

    &-text {
      padding-left: 10px;
      font-family: Raleway;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 2%;
      color: $marine;
    }
  }
}

a.no-style {
  color: inherit;
  text-decoration: underline;
  padding-left: 5px;
}

.chatbot-container {
  position: absolute;
  margin: -35px 0 0 -224px;
  padding-bottom: 73px;
}
