@import '../../../../styles/colors.scss';

.faq-dropdown-container {
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin: 23px 0 20px 54px;
  flex-wrap: wrap;
  overflow: hidden;
}

.arrow {
  margin-right: 13px;
}

.dropdown-title {
  display: flex;
  flex: 0 0 calc(100% - 30px);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2%;
  color: $slate;
  height: 25px;
  font-weight: 600;
  align-items: center;
  vertical-align: middle;
  // margin-bottom: 20px;
  cursor: pointer;
}

.dropdown-content-hide {
  opacity: 0;
  height: 0;
}

.dropdown-content {
  opacity: 1;
  transition: opacity 0.2s;
  height: auto;
  display: flex;
  margin: 0 22px 20px 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2%;
  color: $slate;
  text-align: justify;
  text-justify: inter-word;
}

.dropdown-separator {
  flex: 0 0 calc(100% + 23px);
  margin: 13px 0 20px -23px;
  border: 1px solid $gray3;
}
