@import 'styles/colors';
.container {
  color: $slate;
  > * {
    margin-top: 26px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  label {
    text-transform: uppercase;
    font-size: 0.6875rem;
    font-weight: 600;
    color: $gray5;
    display: block;
    margin-bottom: 11px;
  }

  .currentBudget {
    font-size: 1.25rem;
    input {
      font-size: 1.25rem;
    }
  }
  .additionalBudget .inputWrapper {
    padding: 0 11px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 40px;
    border: 1px solid $gray4;
    &:focus-within {
      border: 1px solid $highlight;
    }
  }

  :where(.currentBudget, .additionalBudget) input {
    &:focus {
      outline: none;
    }
    height: 100%;
    flex-grow: 1;
    color: $slate;
    font-weight: 500;
    border: none;
    background: none;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
