.cart {
  display: flex;
  padding-right: 22px;
  width: 1180px;
  justify-content: space-between;

  &-items {
    width: 684px;
    padding: 24px 0px 0px 0px;
  }
}
