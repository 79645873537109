@import '../../../../styles/colors.scss';

.cardBody {
  padding: 0 16px;
  > * {
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $gray3;
    }
  }
}
