@import '../../styles/colors.scss';

$secondary-hover: $marine-hover;

.container {
  :global(.dzu-dropzone) {
    overflow: auto;
    border: none;
  }

  :global(.dzu-submitButton:disabled) {
    display: none;
  }

  :global(.dzu-submitButton) {
    display: none;
  }

  progress {
    display: none;
  }
}
.thumbnailsContainer {
  display: flex;
  column-gap: 27px;
  row-gap: 21px;
  flex-wrap: wrap;
}
