.item {
  display: flex;
  gap: 24px;
  padding: 24px 0;
  width: 600px;
}

.item img {
  width: 100%;
}

.imageContainer {
  width: 196px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 13px;
  .campaignName {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    display: flex;
    align-items: center;
    color: #25303b;
    margin-top: 0;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
