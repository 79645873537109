@import '../../../../styles/colors.scss';

.video-selector-container {
  margin-top: 23px;
}

.upload-video-label {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 4%;
  color: $gray5;
  text-align: left;
  font-weight: 600;
  margin-bottom: 4px;
}

.browse-files-button {
  background: #ffffff;
  height: 32px;
  width: 120px;
  border-radius: 4px;
  border: solid 1px $marine;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2%;
  color: $marine;
  padding: 4px 10px 4px 10px;
  cursor: pointer;
}
