.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: white;
  border-radius: 10px;

  &-full {
    width: 100%;
  }

  &-fullHeight {
    height: 100%;
  }
}
