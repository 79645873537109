.buildTargetAudience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 448px;
  align-self: center;

  #input-form {
    margin-bottom: 24px;
  }

  &-go-back {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #3e5156;
      margin-left: 5px;
    }
  }

  .team-message-text {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3e5156;
    //margin-left: 5px;
    margin: 0px;
    text-align: center;
  }

  .team-message-text-container {
    margin-bottom: 16px;
  }

  .book-call {
    background: #ffffff;
    border: 1px solid #3e5156;
    border-radius: 4px;
    width: 107px;
    height: 32px;
    cursor: pointer;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: 0.02em;

    /* Branding colors/Secondary */

    color: #3e5156;
  }

  .book-call-text {
    margin-bottom: 8px;

    p {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      /* or 145% */

      letter-spacing: 0.02em;

      /* Branding colors/Primary */
      margin: 0px;
      color: #25303b;
      text-align: left;
    }
  }

  .voters-text {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #3e5156;
    margin-left: 5px;
    text-align: start;
  }

  .voters-container {
    display: flex;
    margin-right: 9px;
    margin-bottom: 26px;

    &-checkbox {
      display: flex;

      p {
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: #25303b;
        margin: 0px;
        padding: 7px 0px 0px 10px;
      }
    }
  }

  &-save-button {
    background: #d6ef59;
    border-radius: 8px;
    border: none;
    width: 86px;
    height: 53px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #25303b;
    align-self: flex-end;
    margin: 40px 0px 24px 0px;
    cursor: pointer;
  }
}
