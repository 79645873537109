@import '../../../../styles/colors.scss';

.sumary {
  &-concept {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #25303b;
    text-align: initial;

    span {
      text-transform: capitalize;
    }
  }
}
