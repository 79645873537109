$dropdown-arrow-width: 24px;
$title-margin-left: 8px;

.container {
  font-family: 'Raleway';
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 16px 0 11px 0;
}

.dropdown {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
}

.arrow {
  justify-self: flex-end;
  width: $dropdown-arrow-width;
}

.title {
  margin-left: $title-margin-left;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #25303b;
  .numCampaigns {
    font-weight: 400;
  }
}

.editable {
  cursor: pointer;
}

.none {
  display: none;
}

.content {
  margin-left: $dropdown-arrow-width + $title-margin-left;
  margin-top: 8px;
  overflow-x: scroll;

  table {
    table-layout: fixed;
    min-width: 1100px;
    padding-top: 8px;
    width: 100%;
    font-size: 14px;
    border-spacing: 0;
    border-collapse: collapse;

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #fbfaf9;
        }
        &:hover {
          background-color: #f6f3f0;
        }
      }
    }

    :is(th, td) {
      text-align: left;
      font-weight: 400;
      letter-spacing: 0.05em;
      color: #474f57;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(var(--col-width) + var(--col-padding));
      padding-left: var(--col-padding, 0);

      &.dates {
        --col-width: 175px;
        --col-padding: 50px;
      }

      &.type {
        --col-width: 110px;
        --col-padding: 50px;
      }

      &.budget {
        --col-width: 90px;
        --col-padding: 20px;
      }

      &.status {
        --col-width: 125px;
        --col-padding: 60px;
      }

      &.actions {
        --col-width: 55px;
        --col-padding: 25px;
      }
    }

    th {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.05em;
      color: #3e5156;
      text-align: justify;

      &.actions {
        text-align: center;
      }

      &.sortable {
        cursor: pointer;
        &:after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 7.5px solid #b0b9bc;
          border-right: 4.5px solid transparent;
          border-left: 4.5px solid transparent;
          margin-left: 3px;
          transition: transform 150ms;
        }
        &.sorted {
          &:after {
            border-top: 7.5px solid #3e5156;
          }
          &.reverse:after {
            transform: rotate(180deg);
          }
        }
      }
    }

    td {
      height: 40px;
      &.type {
        text-transform: capitalize;
      }

      &.dates {
        text-align: left;
      }

      &.status {
        text-align: left;
        letter-spacing: 0.04em;
        text-transform: capitalize;

        .pill {
          display: inline-block;
          border-radius: 50%;
          width: 10px;
          height: 10px;
          background: #d6ef59;
          margin-right: 5px;

          &.pre-launch,
          &.draft {
            background: #cacfd2;
          }

          &.paused,
          &.pause-requested,
          &.start-requested {
            background: #bbcc66;
          }

          &.ended,
          &.canceled {
            background: #94a1a6;
          }
        }
      }
    }
  }
}
