@import '../../../../../../styles/colors.scss';
.container {
  font-size: 0.875rem;
}
.name {
  font-weight: 600;
  letter-spacing: 0.02em;
  color: $slate;
  margin: 0px;
}

.value {
  font-weight: normal;
  letter-spacing: 0.05em;
  color: $marine;
  margin: 0;
  margin-left: 5px;
}
