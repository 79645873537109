.targetAudience {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #25303b;
    margin: 11px 0px 24px 0px;
  }

  &-list-container {
    padding: 16px 16px 16px 16px;
    background: #ffffff;
    border: 1px solid #3e5156;
    border-radius: 10px;
    width: 448px;
    height: 414px;
    align-self: center;

    &-list {
      overflow: auto;
      height: 300px;
    }

    &-element {
      display: flex;
      justify-content: space-between;
      margin-right: 15px;
      cursor: pointer;
      background-color: #ffffff;

      &:hover {
        background: #f1f3f4;
      }

      img {
        margin-right: 3px;
        display: none;
      }
    }

    &-element-selected {
      display: flex;
      justify-content: space-between;
      margin-right: 15px;
      background-color: #f1f3f4;
      cursor: pointer;

      img {
        margin-right: 3px;
        display: block;
      }
    }

    &-options {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #25303b;
      margin: 0px 0px 0px 0px;
      text-align: start;
      height: 28px;
      padding: 5px 0px 0px 5px;
      border-radius: 2px;
    }
  }

  &-buttons-container {
    padding: 0px 0px 8px 0px;
    display: flex;
    justify-content: flex-end;
  }

  &-upload-button {
    background: #ffffff;
    border: 1px solid #3e5156;
    border-radius: 4px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3e5156;
    margin: 0px 16px 0px 0px;
    cursor: pointer;
  }

  &-new-button {
    background: #d6ef59;
    border: 1px solid #d6ef59;
    border-radius: 4px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3e5156;
    cursor: pointer;

    &:hover {
      background-color: #ecf3b6;
      color: #71777d;
    }
  }

  &-save-button {
    border-radius: 8px;
    border: none;
    width: 86px;
    height: 53px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    align-self: flex-end;
    margin: 40px 0px 24px 0px;
    cursor: pointer;

    &--active {
      background: #d6ef59;
      color: #25303b;

      &:hover {
        background-color: #ecf3b6;
        color: #71777d;
      }

      &:active {
        background-color: #bbcc66;
        color: #25303b;
      }
    }

    &--disabled {
      background-color: #eaedef;
      pointer-events: none;
      color: #ccd0d6;
    }
  }
}
