@import '../../styles/colors.scss';

// Background variables

$header-border-bottom: $gray3;
$title-font-color: $slate;

.campaignManagerAdmin {
  width: 100%;
  padding: 0px 18px 30px 0px;

  &-search {
    margin: 0px 17px;
    border-bottom: 1px solid #ccd0d6;
  }

  &-container {
    width: 100%;
    padding-top: 20px;
  }

  &-header {
    border-bottom: 1px solid $header-border-bottom;
    padding: 25px 16px 25px 16px;
    display: flex;
    align-items: center;
  }

  &-body {
    padding: 0px 17px 25px 17px;

    //height: 399px;
  }
}
