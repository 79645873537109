.emptyStateClients {
  display: flex;
  flex-direction: column;
  padding-bottom: 172px;

  &-title {
    border-bottom: 1px solid #ccd0d6;
    padding: 24px 16px 24px 16px;
    display: flex;
    p {
      margin: 0px;
      margin-right: 4px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      align-items: center;
      color: #71777d;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 144px 350px 172px 350px;
  }

  &-primary-text {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #25303b;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  &-secondary-text {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #25303b;
    margin-bottom: 24px;
    margin-top: 0px;
  }

  &-button {
    width: 195px;
    cursor: pointer;
  }
}
