@import 'styles/colors.scss';

.container {
  font-size: 0.875rem;
}

.breakdownContainer {
  display: flex;
  flex-direction: column;
  > * {
    padding: 16px 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray3;
    }
  }
}

.noChangesContainer {
  font-weight: 600;
  :last-child {
    color: $gray4;
  }
}

.amountDue {
  margin-top: 19px;
  &.emphasizeAmountDue {
    :first-child {
      font-size: 1.25rem;
    }
    :last-child {
      font-size: 2.25rem;
    }
  }
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
