@import '../../styles/colors.scss';

// Background variables

.overview {
  margin: 0px;
  padding: 0px 24px 0px 0px;
  display: flex;
  flex-direction: row;

  &-flexContainerRow {
    display: flex;
    margin: 0px;
    //height: 100%;
    width: 30vw;
    // flex-wrap: wrap;
    flex-direction: column; // just for new overview design
  }

  &-graphContainer {
    display: flex;
    margin: 0px 24px 0px 0px;
    height: 100%;
    width: 50vw;
    flex-wrap: wrap;
  }
}
