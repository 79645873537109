@import '../../styles/colors.scss';

// COLORS //
$background-marine: $marine;

/////////////////////
// ELEMENT STYLING //
/////////////////////
.infoCard {
  background-color: $background-marine;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 0px 10px 10px;
  //width: 212px; normal size
  width: 100%;
  height: 126px;
  display: flex;
  flex-direction: column;

  &-margin-left {
    margin-left: 12px;
  }

  &-margin-right {
    margin-right: 12px;
  }

  &-text {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0px 0px 16px 0px;
  }

  &-number {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin: 0px;
  }

  img {
    margin-left: 3px;
    width: 24px;
    height: 24px;
  }
}
