@import '../../styles/colors.scss';

// Background variables

$primary-color: $highlight;
$primary-hover: $highlight-hover;
$primary-hover-text: $slate-hover;
$secondary-hover: $marine-hover;

// Icon variables

$add-hover-color: $slate-hover;
$add-disabled-color: $gray3;

/////////////////////
// ELEMENT STYLING //
/////////////////////

.button {
  // CORE FORMATTING //
  background-color: $primary-color;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  gap: 5px;
  font-style: normal;
  color: $marine;
  width: fit-content;
  white-space: nowrap;

  &.secondary {
    background-color: transparent;
    border: 1px solid $marine;
  }

  img {
    width: 24px;
    height: 24px;
    margin: 0px;
    padding: 0px;
    display: block;
  }

  &:hover {
    &.secondary {
      border-color: $secondary-hover;
      color: $secondary-hover;
    }

    &.primary {
      background-color: $primary-hover;
      color: $primary-hover-text;
    }

    .add path {
      stroke: $add-hover-color;
    }
  }

  &:active {
    &.primary {
      background-color: #bbcc66;
      color: #25303b;
    }
  }

  // DISABLED //

  &:disabled {
    pointer-events: none;
    color: $gray3;

    &.secondary {
      border-color: $gray3;
    }
    &.primary {
      background-color: $gray2;
    }

    .add path {
      stroke: $add-disabled-color;
    }
  }

  &.loading {
    pointer-events: none;
  }

  // SIZES //

  &.small {
    border-radius: 4px;
    padding: 0px 10px;
    min-height: 31px;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
  }

  &.big {
    padding: 12px 24px;
    border-radius: 8px;
    min-height: 48px;
    max-height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
  }

  &.list {
    width: 81px;
  }

  &.navigateToLogin {
    height: 53px;
    padding: 15px 24px 14px;
  }
}
