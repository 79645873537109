.emptyState-big {
  display: flex;
  flex-direction: column;
  padding: 57px 196px 47px 196px;

  &-text {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #25303b;
  }
}

.emptyState-small {
  display: flex;
  flex-direction: column;
  padding: 50px 99px 58px 99px;

  &-text {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #25303b;
  }
}
