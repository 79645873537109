@import '../../styles/colors.scss';

// Background variables

$header-border-bottom: $gray3;
$title-font-color: $slate;

.campaignsInternalDashboard {
  width: 100%;
  padding: 0px 18px 24px 0px;

  &-search {
    margin: 0px 10px 10px 10px;
  }

  &-container {
    width: 100%;
    padding-top: 20px;
  }

  &-header {
    border-bottom: 1px solid $header-border-bottom;
    padding: 25px 16px 25px 16px;
    display: flex;
    align-items: center;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: $title-font-color;
      margin: 0px 104px 0px 0px;
      width: 201px;
    }
  }

  &-body {
    padding: 0px 16px 25px 16px;
    // height: 399px;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #687477;
      margin-bottom: 24px;
      text-align: left;
    }

    table {
      width: 100%;
    }

    th {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #3e5156;
      padding-bottom: 16px;
      text-align: left;
    }

    td {
      text-align: left;
      padding-bottom: 16px;
    }

    #listName {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #25303b;
      width: 150px;
      //  padding-right: 30px;
    }

    #dateAdded {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #474f57;
    }

    #status {
      width: 96px;
    }

    #actions-title {
      text-align: center !important;
    }

    #actions {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
