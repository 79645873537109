.flightDates {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #25303b;
    margin: 11px 0px 24px 0px;
  }

  &-button-container {
    display: flex;
    justify-content: flex-end;
  }

  &-save-button {
    border-radius: 8px;
    border: none;
    width: 86px;
    height: 53px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    align-self: flex-end;
    margin: 40px 0px 24px 0px;
    cursor: pointer;

    &--active {
      background: #d6ef59;
      color: #25303b;

      &:hover {
        background-color: #ecf3b6;
        color: #71777d;
      }

      &:active {
        background-color: #bbcc66;
        color: #25303b;
      }
    }

    &--disabled {
      background-color: #eaedef;
      pointer-events: none;
      color: #ccd0d6;
    }
  }

  &-reset-button {
    background: transparent;
    border-radius: 8px;
    border: none;
    height: 53px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #25303b;
    align-self: flex-end;
    margin: 40px 0px 24px 0px;
    cursor: pointer;
  }
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #d6ef59 !important;
  color: #25303b;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start:not(.DayPicker-Day--outside) {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  background-color: #d6ef59 !important;
  color: #25303b !important;
}

.Selectable .DayPicker-Day--end:not(.DayPicker-Day--outside) {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  background-color: #d6ef59 !important;
  color: #25303b !important;
}
