@import '../../../../styles/colors.scss';

.campaignAddedMessage {
  display: flex;

  &-control {
    width: 100%;
    margin: 0px 23px 0px 0px;
  }

  &-actions {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 100px;
    padding-left: 32px;
    padding-right: 32px;
    width: 564px;
    margin: auto;
  }

  &-scroller {
    height: 713px;
    overflow: auto;
  }

  &-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #25303b;
    margin: 24px 16px 25px 16px;
    padding: 24px 16px 25px 16px;
    border-bottom: 1px solid $gray3;
    margin: 0px;
  }

  .checkImg {
    margin-top: 203px;
    margin-bottom: 56px;
  }
}
