.orderPlaced {
  display: flex;
  flex-direction: column;
  width: 325px;
  align-items: center;
  margin: auto;
  margin-bottom: 174px;
  margin-top: 40px;
  width: 100%;

  &-mainTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #25303b;
    margin-top: 24px;
    margin-bottom: 16px;
    width: 517px;
  }

  &-secondTitle {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #25303b;
    margin-top: 0px;
    margin-bottom: 16px;
    width: 517px;
  }

  &-actions-container {
    display: flex;
    width: 300px;
    justify-content: space-evenly;
    margin-top: 56px;
  }
}
