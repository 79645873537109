@import '../../../../styles/colors.scss';

.container {
  padding: 4px 0;
  font-size: 1rem;
  font-weight: 600;
  color: $slate;
  margin: 0;
  list-style: none;

  details {
    summary {
      list-style: none;
      cursor: default;
      pointer-events: none;
      button {
        border: none;
        background: none;
        color: $marine;
        font-size: 0.6875rem;
        font-weight: 600;
        cursor: pointer;
        pointer-events: initial;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .detailsContainer {
      margin-left: 18px;
      padding: 8px 11px;
      border-radius: 6px;
      background-color: $snow;
    }
  }

  .item {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;
    padding: 16px 0;
    line-height: 1.5;
    :last-child {
      text-align: right;
    }
  }

  .total {
    font-weight: 500;
    :first-child {
      font-size: 1.25rem;
    }
    :last-child {
      font-size: 2.25rem;
    }
  }
}
