@import '../../../../styles/colors.scss';

.editLiveCampaign {
  &-section {
    &-banners {
      .editLiveCampaign-section-body {
        display: flex;
        overflow-x: scroll;
        gap: 8px;
        img {
          max-width: 144px;
        }
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      color: $marine;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 4px;
    }
    &-body {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
