@import '../../../../styles/colors.scss';

.AddOrDraft {
  height: 179px;
  width: 448px;
  padding: 32px 24px 32px 24px;

  &-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &-checkbox {
    display: flex;
    margin-bottom: 28px;
    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #000000;
      margin: 0px 0px 0px 8px;
      text-align: initial;
      width: 350px;
    }
  }
}
