@import 'styles/colors';
.container {
  color: $slate;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  p,
  label {
    color: $gray5;
    font-size: 0.6875rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  label {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 10px;
  }

  p {
    margin-top: 26px;
  }

  .currentFlightDates {
    font-size: 1.25rem;
    display: flex;
    margin-top: 26px;

    input {
      flex: 1;
      font-size: 1.25rem;
    }
  }

  :where(.currentFlightDates) input {
    &:focus {
      outline: none;
    }
    background: none;
    border: none;
    color: $slate;
    flex-grow: 1;
    font-weight: 500;
    height: 100%;
  }

  .buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 26px;
  }
}
