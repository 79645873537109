@import 'styles/colors.scss';

.container {
  --preview-container-size: 175px;
  --preview-container-padding: 12px;
  position: relative;
  width: var(--preview-container-size);
  height: var(--preview-container-size);
  border: 1px solid $gray4b;
  padding: var(--preview-container-padding);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  .imgOuterWrapper {
    width: 100%;

    .imgContainer {
      width: 100%;
      aspect-ratio: 15/12;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .caption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 0.6875rem;
  }
}

.discardButton {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(40%) translateY(-40%);
  border: none;
  background: none;
  width: fit-content;
  height: fit-content;
  padding: 0;
  cursor: pointer;

  img {
    display: block;
  }
}
