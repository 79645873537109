@import './../../../../../../styles/colors.scss';

.determineBudget {
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #25303b;
    margin: 11px 0px 24px 0px;
  }

  .button {
    border-radius: 8px;
    border: none;
    width: 86px;
    height: 53px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    align-self: flex-end;
    margin: 40px 0px 24px 0px;
    cursor: pointer;

    &--active {
      background: #d6ef59;
      color: #25303b;

      &:hover {
        background-color: #ecf3b6;
        color: #71777d;
      }

      &:active {
        background-color: #bbcc66;
        color: #25303b;
      }
    }

    &--disabled {
      background-color: #eaedef;
      pointer-events: none;
      color: #ccd0d6;
    }
  }
}
