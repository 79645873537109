@import '../../../../styles/colors.scss';

// Background variables

$header-border-bottom: $gray3;
$title-font-color: $slate;

.activeCampaignsList {
  //width: 684px;
  width: 50vw;
  //margin: 18px 0px 0px 0px;
  padding: 0px;
  height: 87vh;

  &-header {
    border-bottom: 1px solid $header-border-bottom;
    padding: 25px 16px 25px 16px;
    display: flex;
    //align-items: center;
    justify-content: space-between;
    margin-bottom: 38px;

    &-empty-State {
      justify-content: space-between;
    }

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: $title-font-color;
      margin: 0px 104px 0px 0px;
      width: 201px;
    }

    #search {
      margin-right: 16px;
    }
  }

  &-body {
    padding: 0px 16px 25px 16px;
    height: 399px;

    p {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #687477;
      margin-bottom: 24px;
      text-align: left;
    }

    table {
      width: 100%;
    }

    th {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: #3e5156;
      padding-bottom: 16px;
      text-align: left;
    }

    td {
      text-align: left;
      padding-bottom: 16px;
    }

    #listName {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #25303b;
      width: 150px;
      padding-right: 30px;
    }

    #dateAdded {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #474f57;
    }

    #status {
      width: 96px;
    }
  }
}
