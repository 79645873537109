@import '../../styles/colors.scss';

.container {
  display: flex;
  align-items: stretch;
  gap: 23px;

  .details {
    flex: 1 0 680px;
  }

  .summary {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1 0 360px;
  }

  .scroller {
    height: 713px;
    overflow: auto;
  }
}
