@import '../../../../styles/colors.scss';

.cardTitle {
  white-space: nowrap;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: $slate;
  margin: 24px 16px 25px 16px;
  padding: 24px 16px 25px 16px;
  border-bottom: 1px solid $gray3;
  margin: 0px;
}
