@import '../../../../../../styles/colors.scss';

.formDropdown {
  display: flex;
  flex-direction: column;
  &-dropdown {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &-title {
      display: flex;
      align-items: center;
      gap: 5px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #25303b;
      margin: 0;
      text-align: start;
    }

    &-arrows {
      justify-self: flex-end;
    }

    &-check {
      width: 19px;
      height: 19px;
    }
  }
  &-content {
    text-align: center;
    margin-top: 13px;
  }

  &-content-none {
    display: none;
  }
}
