@import '../../styles/colors.scss';

// COLORS //

/////////////////////
// ELEMENT STYLING //
/////////////////////

.topNav {
  //  height: 56px;
  width: 100%;
  padding-top: 24px;
  padding-right: 22px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;

  &-url-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;

    &-home-title {
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.05em;
      color: #3e5156;
      margin: 0px 0px 5px 0px;
      text-align: start;

      & a {
        color: #3e5156;
        text-decoration: none;
      }
    }

    &-page-title {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 35px;
      display: flex;
      align-items: flex-end;
      color: #25303b;
      margin: 0px;

      & a {
        font-family: Blacker Pro Display;
        color: #25303b;
        text-decoration: none;
      }
    }
  }

  &-user-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
  }

  img {
    cursor: pointer;
  }
}
