.comingSoon {
  display: flex;
  width: 100%;
  //height: 393px;
  justify-content: center;
  align-items: center;
  margin-top: 145px;
  flex-direction: column;
  padding: 0px 275px 173px 275px;

  img {
    width: 325px;
    height: 237px;
  }

  .primaryText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #25303b;
  }

  .secondaryText {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #3e5156;
  }
}
