.tableDropdownAdmin {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccd0d6;
  padding: 0px;

  &-dropdown {
    //height: 64px;
    display: flex;
    justify-content: left;
    padding: 16px 0px 13px 0px;
    cursor: pointer;

    &-title {
      margin: 7px 0 0 8px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #25303b;
    }

    &-arrows {
      justify-self: flex-end;
      padding-top: 3px;
    }

    &-check {
      width: 24px;
      height: 24px;
      padding-top: 5px;
    }
  }

  &-content-none {
    display: none;
  }

  &-content {
    // height: 100px;
    table {
      padding: 4px 0 16px 0;
      width: 100%;
      //	border-top: 1px solid #CCD0D6;
    }

    th {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #3e5156;
      text-align: justify;
    }

    td {
      text-align: left;
      //padding-bottom: 16px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: #474f57;
    }

    tr {
      margin: 0 0 10px 0;
    }

    &-live {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #d6ef59;
      border-radius: 200px;
      //	margin: 0 0 18px 0;
      height: 31px;
      width: 118.6px;
      font-family: 'Raleway' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-align: center !important;
      letter-spacing: 0.04em !important;
      text-transform: uppercase !important;
      color: #25303b !important;
    }

    &-pre-launch {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #cacfd2;
      border-radius: 200px;
      //	margin: 0 0 18px 0;
      height: 31px;
      width: 118.6px;
      font-family: 'Raleway' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-align: center !important;
      letter-spacing: 0.04em !important;
      text-transform: uppercase !important;
      color: #25303b !important;
    }

    &-paused {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #cacfd2;
      border-radius: 200px;
      //	margin: 0 0 18px 0;
      height: 31px;
      width: 118.6px;
      font-family: 'Raleway' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-align: center !important;
      letter-spacing: 0.04em !important;
      text-transform: uppercase !important;
      color: #25303b !important;
    }

    &-ended {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #94a1a6;
      border-radius: 200px;
      //	margin: 0 0 18px 0;
      height: 31px;
      width: 118.6px;
      font-family: 'Raleway' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-align: center !important;
      letter-spacing: 0.04em !important;
      text-transform: uppercase !important;
      color: #25303b !important;
    }

    &-canceled {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #94a1a6;
      border-radius: 200px;
      //	margin: 0 0 18px 0;
      height: 31px;
      width: 118.6px;
      font-family: 'Raleway' !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      text-align: center !important;
      letter-spacing: 0.04em !important;
      text-transform: uppercase !important;
      color: #25303b !important;
    }
  }
}
