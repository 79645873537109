@import 'styles/colors';

.section {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 12px;
  font-weight: 400;

  &:first-child {
    padding-top: 0;
    font-weight: 600;
  }
  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray3;
  }
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
