@import '../../styles/colors.scss';

// Color variables

$label-font-color: $gray5;
$input-border-color: $gray4;
$input-border-color-focus: $highlight;
$input-placeholder-color-focus: $slate;
$input-placeholder-color: $gray3;
$input-error: $error;

/////////////////////
// ELEMENT STYLING //
/////////////////////

.container {
  display: flex;
  flex-direction: column;

  label {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: initial;
    color: $label-font-color;
    margin-bottom: 11px;
  }

  span {
    color: $input-error;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-align: initial;
    margin-top: 10px;
  }
}

.textarea-container {
  display: flex;
  border-radius: 8px;
  .form--textarea {
    width: 100%;
    height: 199px;
    border: 1px solid $input-border-color;
    border-radius: 8px;
    padding: 19px 11px;

    &:focus {
      border: 2px solid $input-border-color-focus;
      outline: none !important;

      &::placeholder {
        color: $input-placeholder-color-focus;
      }
    }

    &::placeholder {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: $input-placeholder-color;
    }
  }

  // SUCCESS STATUS STYLING //

  &--success {
    border: 1px solid $input-border-color;
    background-color: white;
    padding-right: 16px;

    .form--textarea {
      //width: 308px;
      height: 54px;
      border: none;
      padding-right: 0px;

      &:focus {
        border: none;
        outline: none !important;
      }
    }
  }

  // FAIL STATUS STYLING //

  &--fail {
    border: 1px solid $input-error;
    background-color: white;
    padding-right: 16px;

    .form--textarea {
      // width: 308px;
      height: 54px;
      border: none;
      padding-right: 0px;

      &:focus {
        border: none;
        outline: none !important;
      }
    }
  }
}
