$slate: #25303b;
$slate-hover: #71777d;
$marine: #3e5156;
$marine-hover: #8d979a;
$dusty-blue: #94a1a6;
$highlight: #d6ef59;
$highlight-hover: #ecf3b6;
$stone: #f6f3f0;
$snow: #fbfaf9;
$error: #f03d3e;
$success: #37c771;
$notification: #1084d8;
$white: #ffffff;
$gray2: #eaedef;
$gray3: #ccd0d6;
$gray4: #919699;
$gray4b: #cacfd2;
$gray5: #545a5e;
