.mailSentSuccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;

  &-messagesContainer {
    margin-bottom: 15px;
  }

  &-mailSentSuccessMessage {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.05em;
    color: #25303b;
    margin: 0px 0px 0px 0px;
    text-align: center;
  }

  &-mailSentSuccessMessageMail {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.05em;
    color: #25303b;
    margin: 0px 0px 0px 0px;
    text-align: center;
    cursor: pointer;
  }

  &-mailSentReturnToLogin {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    letter-spacing: 0.05em;
    color: #25303b;
    margin: 200px 0px 0px 0px;
    text-align: center;
    cursor: pointer;
  }
}
