@import '../../styles/colors.scss';

.iconButton {
  --height: 12px;
  background-color: transparent;
  padding: 0;
  border: 0;
  align-items: center;
  cursor: pointer;
  color: $marine;
  height: var(--height);

  img {
    height: var(--height);
    aspect-ratio: 1;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
