.topNavManager {
  display: flex;
  justify-content: left;
  padding: 0 8px 13px 13px;
  border-bottom: 1px solid #ccd0d6;
  margin: 0 0 16px 0;
  height: 46px;

  p {
    padding-bottom: 6px;
    margin: 0 16px 0 0;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #72777d;
    cursor: pointer;

    &:hover,
    &.active {
      color: #25303b;
    }

    &.active {
      border-bottom: 3px solid #d6ef59;
      border-radius: 3px;
    }
  }
}
