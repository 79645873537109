@import '../../styles/colors.scss';

// COLORS //
$font-color: $marine;
$menu-item-bc-active: $slate;

/////////////////////
// ELEMENT STYLING //
/////////////////////

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 236px !important; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  //  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: transparent;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 24px;

  #logo {
    width: 211px;
    height: 65.68px;
    margin-bottom: 27px;
    margin-left: 23px;
  }

  &-menu-link-container {
    display: flex;
    padding-left: 23px;
    cursor: pointer;

    #overviewWhite,
    #campaignsWhite,
    #draftsWhite,
    #audienceWhite,
    #clientsWhite,
    #faqWhite {
      display: none;
    }

    &-active {
      background-color: $menu-item-bc-active;
      border-radius: 0px 33px 33px 0px;
      pointer-events: none;

      #overview,
      #campaigns,
      #drafts,
      #audience,
      #clients,
      #faq {
        display: none;
      }

      #overviewWhite,
      #campaignsWhite,
      #draftsWhite,
      #audienceWhite,
      #clientsWhite,
      #faqWhite {
        display: block;
      }
      a {
        color: #ffffff !important;
      }
    }

    a {
      padding: 6px 0px 6px 8px;
      text-decoration: none;
      font-size: 25px;
      color: $font-color;
      display: block;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      display: flex;
      align-items: center;
      width: 213px;
      height: 54px;
    }

    &:hover {
      background-color: #71777d;
      border-radius: 0px 33px 33px 0px;

      #overview,
      #campaigns,
      #drafts,
      #audience,
      #clients,
      #faq {
        display: none;
      }

      #overviewWhite,
      #campaignsWhite,
      #draftsWhite,
      #audienceWhite,
      #clientsWhite,
      #faqWhite {
        display: block;
      }

      a {
        color: #ffffff;
      }
    }
  }
}

/* Style page content */
.main {
  margin-left: 260px;
}
