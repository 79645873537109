.actions-container {
  width: 19px;
  height: 19px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.actions-dropdown {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: rgba(174, 186, 197, 0.45) 0px 1px 1px;
  width: 139px;
  position: absolute;
  margin-left: -127px;
  margin-top: 104px;
}

.actions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.action-element {
  height: 32px;
  padding: 6px 0px 6px 5px;
  line-height: 20px;
  letter-spacing: 5%;

  &-true {
    cursor: pointer;
  }

  &-true:hover {
    background-color: #eeeeee;
  }

  &-false {
    color: #b3adad;
  }
}

.action-element:hover {
  background-color: #f1f3f4;
}
